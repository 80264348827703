import React from 'react'
import Layout from '../components/layout'
import PageHeader from '../components/pageHeader'
import {StaticQuery, graphql} from 'gatsby'
const NotFoundPage = () => (
  <StaticQuery
    query={graphql`
      query {
        ContactPageImage: file(relativePath: { eq: "traffic.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 2880, maxHeight: 1200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <Layout>
        <PageHeader image={data.ContactPageImage.childImageSharp}>
          Sorry, This Page Doesn’t Exist
        </PageHeader>
      </Layout>
    )}
  />
)

export default NotFoundPage
